<template>
  <v-container fill-hight fluid style="background-color: #ebedf3" class="pa-0">
    <Selecionar :selecionar="selecionar">
      <v-select
        dense
        hide-details
        :items="leiloes"
        item-text="nome"
        v-model="leilaoSelecionado"
        return-object
        style="max-width: 400px"
        background-color="#ffffff"
        single-line
        @change="changeLeilao"
        label="Selecione um Leilão"
        outlined
      >
      </v-select>
    </Selecionar>
    <v-row class="pt-0">
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-row class="pb-5">
          <v-col cols="12" md="6" lg="6" xl="6" class="py-0">
            <v-select
              dense
              hide-details
              :items="leiloes"
              item-text="nome"
              v-model="leilaoSelecionado"
              return-object
              style="max-width: 400px"
              background-color="#ffffff"
              single-line
              @change="changeLeilao"
            >
            </v-select>
            <!-- <span v-else><b>Leilão:</b> {{ leilaoSelecionado.nome }}</span> -->
          </v-col>
        </v-row>
        <v-row class="hidden-sm-and-down painel">
          <v-col cols="12" md="6" lg="6" xl="6" class="py-0">
            <p>
              <span class="font-weight-bold">Qtd. Lotes: </span
              ><span>{{ leilaoSelecionado.qtdLotes }}</span>
            </p>
            <p>
              <span class="font-weight-bold">Descrição: </span
              >{{ leilaoSelecionado.titulo }}
            </p>
            <p>
              <span class="font-weight-bold">Comitente: </span
              >{{ leilaoSelecionado.nomeEmpresa }}
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6" class="py-0">
            <p>
              <span class="font-weight-bold">Localização: </span
              ><span>{{ leilaoSelecionado.localizacao }} </span>
            </p>
            <p>
              <span class="font-weight-bold">Data: </span
              >{{ leilaoSelecionado.dataLeilao | formataDataHora }}
            </p>
            <!-- <p>
              <span class="font-weight-bold">Status: </span>Ao vivo
              <v-icon size="14" class="pa-0" dark :color="
                leilaoSelecionado.statusSite == 'aberto' ? 'success' : 'error'
              ">mdi-checkbox-blank-circle</v-icon>
            </p> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        class="pa-4"
        :align="!mobile ? 'right' : 'center'"
      >
        <div
          class="youtube"
          v-html="leilaoSelecionado.linkYoutube"
          v-if="leilaoSelecionado.linkYoutube"
        ></div>
        <v-img
          v-else
          max-width="300"
          :src="require('@/assets/youtubeOffline.jpg')"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <!-- PRÓXIMOS LOTES -->
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        xl="3"
        class="py-5"
        :order="mobile ? 2 : 1"
        align="center"
      >
        <h3 class="text-center">PRÓXIMOS LOTES ({{ totalAndamento }})</h3>

        <div id="virtual" class="virtual-scroll" @scroll="onScroll">
          <!-- <v-card height="200" v-for="n in 20" :key="n">
          {{n}}
        </v-card> -->
          <CardLote
            v-for="(lote, i) in proximosLotes"
            :key="i"
            :lote="lote"
            :foto="false"
            @click="mostrarLote(lote)"
          />

          <div class="text-center">
            <v-btn icon :loading="loadingProximosLotes"> </v-btn>
          </div>
        </div>
      </v-col>

      <!-- EM LEILÃO -->
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        class="py-5"
        :order="mobile ? 1 : 2"
      >
        <h3>EM LEILÃO</h3>
        <v-card elevation="0" rounded="lg">
          <v-card-text class="px-2">
            <v-row
              v-show="mobile"
              class="mx-0 mb-1 mobile"
              justify="space-between"
            >
              <div class="d-flex align-center">
                <span class="text--secondary mr-2">TEMPO RESTANTE: </span
                ><span
                  :style="
                    emLeilao && emLeilao.info.emFechamento
                      ? 'color: #ff5252 !important'
                      : 'color: #bbbbbb'
                  "
                >
                  <span v-if="emLeilao && countStatus">{{ countStatus }}</span>
                  <span
                    v-if="
                      emLeilao &&
                      emLeilao.info &&
                      emLeilao.info.status.includes('Aguardando') &&
                      !countStatus
                    "
                    >{{ tempoRestante }}</span
                  >
                </span>

                <count-down
                  v-if="emLeilao && emLeilao.info.emFechamento && !countStatus"
                  :final="emLeilao.info.dataEncerramento"
                  :atual="emLeilao.info.dataAtualUtc"
                  @FinishCountdown="onFinishCountDown"
                />
              </div>

              <div>
                <v-btn
                  dark
                  small
                  color="success"
                  class="square"
                  @click="comando('iniciar')"
                  v-show="
                    emLeilao &&
                    !emLeilao.info.emFechamento &&
                    emLeilao.info.status != 'Encerrado'
                  "
                >
                  <v-icon> mdi-play </v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  small
                  class="square"
                  @click="comando('iniciar')"
                  v-show="
                    emLeilao && emLeilao.info.emFechamento && !countStatus
                  "
                  style="margin-right: 5px"
                >
                  <v-icon> mdi-reload </v-icon>
                </v-btn>
                <v-btn
                  dark
                  color="warning"
                  small
                  class="square"
                  @click="comando('parar')"
                  v-show="
                    emLeilao && emLeilao.info.emFechamento && !countStatus
                  "
                >
                  <v-icon> mdi-pause </v-icon>
                </v-btn>

                <v-btn
                  dark
                  small
                  class="square"
                  color="error"
                  @click="comando('encerrar')"
                  v-show="countStatus"
                  :loading="encerrando"
                >
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
              </div>
            </v-row>
            <v-row justify="space-between" class="ma-0">
              <div class="card-info">
                <p class="text--secondary">LOTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao.numeroLote }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">LANCE ATUAL</p>
                <v-sheet height="20" width="100" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    width="100"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  R$ {{ emLeilao && emLeilao.info.ultimoLance | formatMoeda }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">ARREMATANTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao && emLeilao.info.usuarioUltimoLance }}
                </p>
              </div>
              <div class="card-info hidden-sm-and-down">
                <p class="text--secondary">TEMPO RESTANTE</p>
                <p
                  class="font-weight-black text-right"
                  :style="
                    emLeilao && emLeilao.info.emFechamento
                      ? countStatus === 'Homologando...'
                        ? 'color: #f9c718 !important'
                        : 'color: #e0280f !important'
                      : 'color: #bbbbbb'
                  "
                >
                  <span
                    v-if="
                      ((emLeilao &&
                        emLeilao.info &&
                        emLeilao.info.status.includes('Aguardando')) ||
                        (emLeilao &&
                          emLeilao.info &&
                          emLeilao.info.status.includes('Aberto'))) &&
                      !countStatus
                    "
                    >{{ tempoRestante }}</span
                  >
                  <span v-else-if="emLeilao && countStatus">{{
                    countStatus
                  }}</span>
                  <count-down
                    v-else-if="
                      emLeilao && emLeilao.info.emFechamento && !countStatus
                    "
                    :final="emLeilao.info.dataEncerramento"
                    :atual="emLeilao.info.dataAtualUtc"
                    @FinishCountdown="onFinishCountDown"
                  />
                </p>
              </div>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
                lg="8"
                xl="8"
                class="pl-0"
                :class="mobile && 'pa-0 pt-3'"
              >
                <v-card
                  rounded="lg"
                  class="clicavel"
                  style="position: relative"
                >
                  <v-carousel hide-delimiters height="260">
                    <v-carousel-item v-if="loading">
                      <v-sheet class="pa-3">
                        <v-skeleton-loader
                          class="mx-auto"
                          type="image"
                        ></v-skeleton-loader>
                      </v-sheet>
                    </v-carousel-item>
                    <v-carousel-item
                      v-else
                      v-for="(foto, i) in emLeilao.fotos"
                      :key="i"
                      :src="foto && foto.arquivo.url"
                      @click="zoom(foto && foto.arquivo.url, i)"
                    >
                    </v-carousel-item>
                  </v-carousel>
                </v-card>

                <!-- <v-row class="mt-2 hidden-sm-and-down" v-if="loading">
                  <v-col cols="6" md="4" lg="4" xl="4" v-for="i in 3" :key="i">
                    <v-card>
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-skeleton-loader
                          class="mx-auto"
                          max-width="250"
                          max-height="80"
                          type="image"
                        ></v-skeleton-loader>
                      </v-responsive>
                    </v-card>
                  </v-col>
                </v-row> -->
              </v-col>
              <v-col
                cols="12"
                md="4"
                lg="4"
                xl="4"
                class="caracteristicas"
                :class="mobile ? 'pa-0' : 'pr-0'"
              >
                <v-btn
                  block
                  color="success"
                  small
                  class="mb-1 hidden-sm-and-down"
                  @click="comando('iniciar')"
                  v-show="
                    emLeilao &&
                    !emLeilao.info.emFechamento &&
                    emLeilao.info.status != 'Encerrado'
                  "
                >
                  <v-icon dark left> mdi-play </v-icon>
                  Iniciar</v-btn
                >
                <v-btn
                  block
                  color="primary"
                  small
                  class="mb-1 hidden-sm-and-down"
                  @click="comando('iniciar')"
                  v-show="
                    emLeilao && emLeilao.info.emFechamento && !countStatus
                  "
                >
                  <v-icon dark left> mdi-reload </v-icon>
                  Reiniciar
                </v-btn>
                <v-btn
                  block
                  color="warning"
                  small
                  class="mb-1 hidden-sm-and-down"
                  @click="comando('parar')"
                  v-show="
                    emLeilao && emLeilao.info.emFechamento && !countStatus
                  "
                >
                  <v-icon dark left> mdi-pause </v-icon>
                  parar</v-btn
                >

                <v-btn
                  block
                  color="error"
                  class="mb-1 hidden-sm-and-down"
                  small
                  @click="comando('encerrar')"
                  v-show="countStatus"
                  :loading="encerrando"
                >
                  <v-icon dark left> mdi-stop </v-icon>
                  encerrar
                </v-btn>
                <v-sheet elevation="2" class="mt-3">
                  <v-row>
                    <v-col>
                      <div class="d-flex align-center my-1">
                        <div class="mr-4">
                          <img
                            :src="require('@/assets/icon1.png')"
                            style="width: 35px"
                          />
                        </div>

                        <div>
                          <p class="font-weight-black text-h4">
                            {{ emLeilao && emLeilao.info.qtdParticipantes }}
                          </p>
                          <p class="text-caption">Participantes</p>
                        </div>
                      </div>

                      <div class="d-flex align-center mb-1">
                        <div class="mr-4">
                          <img
                            :src="require('@/assets/icon2.png')"
                            style="width: 35px"
                          />
                        </div>

                        <div>
                          <p class="font-weight-black text-h4">
                            {{ emLeilao && emLeilao.info.qtdVisualizacoes }}
                          </p>
                          <p class="text-caption">Visualizações</p>
                        </div>
                      </div>

                      <div class="d-flex align-center mb-1">
                        <div class="mr-4">
                          <img
                            :src="require('@/assets/icon3.png')"
                            style="width: 35px"
                          />
                        </div>

                        <div>
                          <p class="font-weight-black text-h4">
                            {{ emLeilao && emLeilao.info.qtdLances }}
                          </p>
                          <p class="text-caption">Lances</p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-if="emLeilao">
              <v-col cols="12" align="center">
                <p class="text-h6">{{ emLeilao.descricao }}</p>
                <hr style="width: 60%" />
              </v-col>
              <v-col
                v-for="(item, i) in emLeilao && emLeilao.campos"
                :key="i"
                cols="6"
                md="3"
                lg="3"
                xl="3"
                class="pa-0 pl-2 pb-2 text-caption"
              >
                <span class="font-weight-bold"
                  >{{ item.loteCampo.descricao }}:</span
                ><br />
                <span class="text--secondary">{{ item.valor }}</span>
              </v-col>
            </v-row>
            <v-row v-if="emLeilao && emLeilao.descricaoDetalhada">
              <v-col>
                <p class="font-weight-black text-caption">
                  DESCRIÇÃO DETALHADA
                </p>
                <p
                  class="text--secondary text-caption"
                  v-html="emLeilao.descricaoDetalhada"
                ></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- LOTES LEILOADOS -->
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        xl="3"
        class="py-5"
        align="center"
        order="3"
      >
        <h3>LOTES LEILOADOS ({{ totalLeiloados }})</h3>

        <div
          id="virtualLeiloados"
          class="virtual-scroll"
          @scroll="onScrollLeiloados"
        >
          <CardLote
            v-for="(lote, i) in lotesLeiloados"
            :key="i"
            :lote="lote"
            :foto="false"
            @click="mostrarLote(lote)"
          />
          <v-btn icon :loading="loadingLotesLeiloados"> </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="zoomImg" max-width="720" max-height="360" v-if="zoomImg">
      <v-carousel v-model="itemCarosel">
        <v-carousel-item
          v-for="(foto, i) in emLeilao.fotos"
          :key="i"
          :src="foto && foto.arquivo.url"
          aspect-ratio="2"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-container>
</template>

<script>
import Leilao from '@/service/leilao';
import CardLote from '../components/card-lote.vue';
import CountDown from '../components/CountDown.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import sweetalertMixins from '../../mixins/sweetalertMixin';
import Selecionar from './selecionar.vue';

export default {
  name: 'PainelLeiloeiro',
  components: {
    CardLote,
    CountDown,
    Selecionar,
  },
  mixins: [sweetalertMixins],
  data() {
    return {
      zoomImg: false,
      urlImg: '',
      itemCarosel: 0,
      contadorLote: {
        total: 0,
      },
      contadores: {
        lances: 0,
      },
      leiloes: [],
      leilaoSelecionado: {},
      lotes: null,
      leiloados: null,
      emLeilao: null,
      proximosLotes: [],
      lotesLeiloados: [],
      loadingProximosLotes: false,
      loadingLotesLeiloados: false,
      countStatus: '',
      loading: true,
      mostrarIniciar: false,
      encerrando: false,
      totalLeiloados: 0,
      totalAndamento: 0,
    };
  },

  computed: {
    ...mapGetters(['getAcao', 'getAcaoLoteAtivo', 'getLeilao']),
    widownHeight() {
      return window.innerHeight * 0.9;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tempoRestante() {
      const tempo = this.leilaoSelecionado.tempoInicioSeg;
      const format = tempo >= 60 ? 'm[m] : s[s]' : 's[s]';
      return moment('00:00', 'mm:ss').add(tempo, 's').format(format);
    },
    selecionar() {
      return !this.leilaoSelecionado.nome;
    },
  },

  methods: {
    ...mapActions(['setAuth', 'iniciar', 'parar', 'encerrar', 'getLoteInfo']),
    ...mapMutations(['setTituloPagina']),

    comando(acao) {
      switch (acao) {
        case 'iniciar':
          if (
            moment()
              .utc()
              .isSameOrBefore(moment(this.leilaoSelecionado.dataLeilao).utc())
          ) {
            this.showToast({ title: 'Aguarde a data e hora do leilão' });
            return;
          }
          this.iniciar(this.emLeilao.loteId);
          this.countStatus = '';
          break;
        case 'parar':
          this.confirm('Realmente deseja parar o lote?').then((result) => {
            if (result.isConfirmed) {
              this.parar(this.emLeilao.loteId);
              this.countStatus = '';
            }
          });
          break;
        case 'encerrar':
          this.encerrando = true;
          this.encerra();
          break;
      }
    },
    zoom(url, index) {
      this.urlImg = url;
      this.itemCarosel = parseInt(index);
      this.zoomImg = true;
    },
    mostrarLote(lote) {
      this.countStatus = null;
      this.$nextTick(() => this.setEmleilao(lote));
    },
    async encerra() {
      try {
        await this.encerrar(this.emLeilao.loteId);
      } catch (error) {
        this.showToast({ title: 'Aguardando encerramento' });
      }
      this.encerrando = false;
    },
    async acaoAoEncerrar() {
      if (this.proximosLotes.length > 1) {
        this.setEmleilao(this.proximosLotes[1]);
      }
      const { id } = this.leilaoSelecionado;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.proximosLotes = lotes.data;
      const leiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });

      this.lotesLeiloados = leiloados.data;
      this.totalLeiloados = leiloados.totalRecords;
      this.totalAndamento = lotes.totalRecords;

      this.encerrando = false;
    },
    async addLotes(id) {
      let page = this.lotes.pageNumber + 1;

      if (page > 1 && this.lotes && page > this.lotes.totalPages) return;

      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      lotes.data.forEach((e) => this.proximosLotes.push(e));

      this.loadingProximosLotes = false;
    },
    async addLotesLeiloados(id) {
      const page = this.leiloados.pageNumber + 1;

      if (page > 1 && this.leiloados && page > this.leiloados.totalPages)
        return;

      this.loadingLotesLeiloados = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'encerrado' });
      this.leiloados = lotes;
      this.totalLeiloados = lotes.totalRecords;

      lotes.data.forEach((e) => this.lotesLeiloados.push(e));

      this.loadingLotesLeiloados = false;
    },
    async onChangeLeilao() {
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.lotes = null;
      await this.addLotes(id);
      this.setEmleilao();
      await this.addLotesLeiloados(id);
    },
    async changeLeilao() {
      this.lotes = null;
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.onJoinGroup(id);
      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      this.proximosLotes = lotes.data;
      this.loadingProximosLotes = false;

      this.setEmleilao();

      this.loadingLotesLeiloados = true;
      const lotesLeiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });
      this.totalLeiloados = lotesLeiloados.totalRecords;
      this.lotesLeiloados = lotesLeiloados.data;
      this.loadingLotesLeiloados = false;
    },
    async onFinishCountDown(finalizar) {
      if (finalizar) {
        this.countStatus = 'Homologando...';
      }
    },
    timeout() {
      return new Promise((resolve) => {
        setTimeout(resolve, 200);
      });
    },
    async setEmleilao(item = null) {
      this.loading = true;
      this.countStatus = null;
      if (!item) {
        item =
          this.proximosLotes.find((e) => e.info.emFechamento) ||
          this.proximosLotes[0];
      }
      const { data } = await Leilao.getLote({ id: item.loteId });
      const resp = await Leilao.getLoteInfo({ id: item.loteId });
      this.emLeilao = data;
      this.emLeilao['info'] = { ...resp.data };
      this.emLeilao['fotos'] = {
        ...data.fotos.filter((e) => e.tipoFoto.visivelSite),
      };

      this.loading = false;
      return;
    },

    async inicializa() {
      try {
        const leiloes = await Leilao.getLeiloesAberto();

        this.leiloes = leiloes.data;

        if (this.getLeilao) {
          this.leilaoSelecionado = { ...this.getLeilao };
          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
          return;
        }

        if (this.leiloes.length == 1) {
          this.leilaoSelecionado = this.leiloes[0];

          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
        }
      } catch (_) {
        //console.error();
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtual');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotes(id);
        return;
      }
      this.toTop = false;
      e.stopPropagation();
    },
    onScrollLeiloados(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtualLeiloados');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotesLeiloados(id);
        return;
      }
      this.toTop = false;
      e.stopPropagation();
    },
    onJoinGroup(leilaoId) {
      this.$signalR.joinLeilao(leilaoId);
    },

    async onEventNewLance(loteinfo) {
      if (this.emLeilao.loteId === loteinfo.loteId) {
        this.countStatus = '';

        if (loteinfo.status === 'Encerrado') {
          this.acaoAoEncerrar();
        }

        this.emLeilao['info'] = { ...loteinfo };
      }
      const lote = this.proximosLotes.find((e) => e.loteId == loteinfo.loteId);
      if (lote) {
        lote.info = { ...loteinfo };
      }
      this.countStatus = null;
    },
  },
  beforeDestroy() {
    this.$signalR.$off('new-lance', this.onEventNewLance);
  },
  filters: {
    formataData(val) {
      if (!val) return '';
      return new Date(val).toLocaleString();
    },
    formataDataHora(val) {
      if (!val) return '';
      return moment(val).utc().local().format('DD/MM/YYYY HH:mm');
    },
    formatMoeda(val) {
      if (!val) return '';
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    },
  },
  async created() {
    this.setTituloPagina('PAINEL LEILÃO');
    await this.inicializa();
    this.$signalR.$on('new-lance', this.onEventNewLance);
  },
};
</script>

<style>
p {
  margin: 0px !important;
}

.tag-leilao {
  position: absolute;
  z-index: 9999;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #222;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  letter-spacing: 1.5px;
}

.virtual-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 645px;
}

.virtual-scroll::-webkit-scrollbar {
  display: none;
}

.card-info div {
  display: flex;
  justify-content: flex-end;
}

.card-info .text--secondary {
  font-size: 11px;
}

.card-info .font-weight-black {
  font-size: 20px;
}

.caracteristicas span {
  font-size: 11px;
}

.clicavel {
  cursor: pointer;
}

.painel {
  font-size: 13px;
}

.mobile .text--secondary {
  font-size: 11px;
}

iframe {
  width: 100%;
  height: 100%;
}

.youtube {
  width: 320px;
  height: 180px;
  border: 3px solid #fff;
  box-shadow: 0px 7px 5px 0px #00000060;
}
</style>
